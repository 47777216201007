<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <!--begin::Form Wizard-->
        <form class="form" @submit.prevent="handleSubmit(_handleSubmit)">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.name') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('commons.name')"
                      type="text"
                      v-model="formData.place"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.employee') }}</label>
                  <validation-provider
                    rules="required" :name="$t('commons.employee')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedEmp"
                      :options="employees"
                      :searchable="false"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='full_name'
                      label='full_name'
                      :placeholder="$t('commons.employee')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.full_name }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.full_name }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.ip') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('commons.ip')"
                      type="text"
                      v-model="formData.ip"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.description') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('commons.description')"
                      type="text"
                      v-model="formData.description"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-end border-top mt-5 pt-10">
            <button
              type="submit"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
              :disabled="isSubmitting"
            >
              {{ $t('commons.submit') }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form Wizard-->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Skeleton } from 'vue-loading-skeleton';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: "NewIp",
  data() {
    return {
      formData: {
        place: "",
        employee_id: "",
        ip: "",
        description: "",
      },
      selectedEmp: null
    };
  },
  components: {
    Skeleton,
    Multiselect
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ips.isLoading,
      isSubmitting: (state) => state.ips.isSubmitting,
      employees: (state) => state.ips.employees,
      ip: (state) => state.ips.ip
    }),
  },
  methods: {
    ...mapActions('ips', ['GET_UPDATE_DATA', 'UPDATE_IP']),
    _handleSubmit() {
      this.formData.employee_id = this.selectedEmp.id;
      this.UPDATE_IP({
          id: this.$route.params.id,
          formData: this.formData
      });
    }
  },
  created() {
    this.GET_UPDATE_DATA(this.$route.params.id).then(() => {
        const { ip, place, description, employee_id } = this.ip;
        this.formData.ip = ip;
        this.formData.place = place;
        this.formData.employee_id = employee_id;
        this.formData.description = description;
        if(employee_id && this.employees.length > 0) {
            this.selectedEmp = this.employees.find(item => +item.id === +employee_id)
        }
    })
  },
};
</script>
